// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/samdozor/Documents/wedding/site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/samdozor/Documents/wedding/site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lodging-js": () => import("/Users/samdozor/Documents/wedding/site/src/pages/lodging.js" /* webpackChunkName: "component---src-pages-lodging-js" */),
  "component---src-pages-registry-js": () => import("/Users/samdozor/Documents/wedding/site/src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */),
  "component---src-pages-rsvp-js": () => import("/Users/samdozor/Documents/wedding/site/src/pages/rsvp.js" /* webpackChunkName: "component---src-pages-rsvp-js" */),
  "component---src-pages-schedule-js": () => import("/Users/samdozor/Documents/wedding/site/src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-thecouple-js": () => import("/Users/samdozor/Documents/wedding/site/src/pages/thecouple.js" /* webpackChunkName: "component---src-pages-thecouple-js" */),
  "component---src-pages-travel-js": () => import("/Users/samdozor/Documents/wedding/site/src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */),
  "component---src-pages-venue-js": () => import("/Users/samdozor/Documents/wedding/site/src/pages/venue.js" /* webpackChunkName: "component---src-pages-venue-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/samdozor/Documents/wedding/site/.cache/data.json")

